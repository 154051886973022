@media screen and (max-width: 767px) {

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin: 0 0 1rem;
  }

  h1, .h1 {
    font-size: 1.5rem;
  }

  h2, .h2 {
    font-size: 1.25rem;
  }

  h3, .h3 {
    font-size: 1.25rem;
  }

  h4, .h4 {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    line-height: 1.14;
  }

  .main-section {
    padding: 3.5rem 0;
  }

  .section-title--center,
  .section-title--faded {
    margin-bottom: 2.5rem;
  }

  .section-title--vertical:after {
    margin: 0 1rem;
  }

  .cta {
    font-size: 0.875rem;
    padding: 1rem 3.75rem 0.875rem 1.125rem;
  }

  .cta:after {
    width: 2.75rem;
  }

  /* CEO QUOTE SECTION */
  .ceo-quote__image {
    right: -7%;
  }

  .ceo-quote__content {
    padding: 7rem 1.375rem 0 1.1875rem;
  }

  .ceo-quote__text {
    max-width: 100%;
  }

  /* HOW WE SECTION */
  .how-we-recruit {
    padding-bottom: 1.5625rem;
  }

  /* G-CORE SECTION */
  .g-core {
    padding-bottom: 0;
  }

  .g-core__wrapper {
    flex-direction: column;
    padding: 0;
  }

  .g-core__column {
    width: 100%;
  }

  .g-core__column.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 7rem;
    padding-top: 0;
  }

  .g-core__text {
    font-size: .75rem;
    line-height: 1.125rem;
    text-align: center;
  }

  .accordion {
    width: 100%;
  }

  .accordion__content p {
    font-size: .875rem;
    line-height: 1.3125rem;
  }

  /* NAVIGATION MENU */
  .menu {
    height: 5rem;
    padding: 0 1.25rem;
  }

  .menu__logo {
    width: 7rem;
  }

  .menu__getquote {
    width: 6.25rem;
  }

  .menu__getquote > .left {
    padding: .313rem .5rem;
    font-size: 0.625rem;
    white-space: nowrap;
  }

  .menu__getquote > .right {
    padding: .5rem;
  }

  .menu__getquote > .right > .arrow--right {
    width: .5rem;
    height: .5rem;
  }

  .menu__items {
    font-size: 0.875rem;
    line-height: 1.42;
  }

  .menu__item-link,
  .menu__item-link:hover {
    padding-top: 0;
  }

  .menu__btn:checked ~ .menu__items {
    max-height: calc(100vh - 5rem);
    height: calc(100vh - 5rem);
  }

  .menu__button--mobile:before {
    width: 1.5rem;
    background-image: url("../img/arrow-right-mobile.svg");
    background-position-x: calc(100% - 0.5rem);
  }

  .menu__button--mobile {
    font-size: .625rem;
    padding: .5rem 2rem .5rem .5rem;
  }

  /* PAGE HEADER */
  .header {
    margin-bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
    row-gap: 3rem;
    padding: 0;
  }

  .header__container {
    position: relative;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0;
    overflow: hidden;
  }

  .header__background {
    width: calc(100% - 1.5rem);
    height: 395px;
    object-fit: cover;
  }

  .header__container:before {
    top: 1.5rem;
    right: -1.5rem;
    left: 0;
  }

  .header__corner.rt {
    right: 1.5rem;
  }

  .header__corner.rb {
    right: 1.5rem;
    bottom: 1.5rem;
  }

  .header__corner.white {
    bottom: -1.5rem;
    right: -1.5rem;
  }

  .header__content {
    position: static;
    padding: 1.5rem 1.5rem 0;
    width: 100%;
  }

  .header__text {
    font-size: 0.875rem;
    line-height: 1.57;
  }

  /* ================================================ */
  /* HOW CAN WE HELP*/
  .squares {
    overflow: hidden;
    margin: 0 -1.5rem;
    width: 100vw;
    padding: 0 1.5rem;
  }

  .squares__item {
    padding: 1.5rem;
    height: 9.75rem;
  }

  .squares__item:hover {
    transform: none;
  }

  .squares__item > img {
    transform: scale(.82);
    transform-origin: top right;
  }

  .squares__item-title {
    max-width: 80%;
    font-size: 0.875rem;
    line-height: 1.42;
  }

  .rectangles__item-text {
    font-size: 0.875rem
  }

  /* CASE STUDIES */
  #case-studies-swiper,
  #case-studies-swiper-for-abroad {
    max-height: 35rem;
    width: 100%;
  }

  #case-studies {
    padding-bottom: 4rem;
  }

  #case-studies > .section-center {
    padding: 0 1.25rem;
    margin: 0;
    flex-direction: column;
    width: 100%;
  }

  .section-title--vertical {
    writing-mode: unset;
    transform: unset;
    font-size: 1.5rem;
  }

  .section-title--vertical:after {
    width: 2.5rem;
    height: .5rem;
    background: linear-gradient(90deg, #262626 0%, #262626 50%, #478ffb 50%, #478ffb 100%);
    margin: 1rem 0 2rem;
  }

  .studies {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .studies__item-content {
    padding: 0 1.5rem 1.5rem;
  }

  .studies__item-title {
    line-height: 1.125;
    margin-bottom: 0.5rem;
  }

  .studies__item-subtitle {
    font-size: 0.875rem;
    line-height: 1.42;
  }

  .studies__item-logo {
    padding: 1rem 1rem 0;
  }

  .learn-more {
    padding: 1.5rem 2rem;
    flex-direction: row;
  }

  .learn-more__icon {
    transform: none;
    margin-top: 0;
    margin-right: 0.5rem;
  }

  .learn-more__text {
    writing-mode: unset;
    transform: unset;
  }

  .studies .swiper-pagination,
  .studies .swiper-button-next,
  .studies .swiper-button-prev {
    display: none;
  }

  /* REVIEWS */

  .reviews {
    margin: 0;
    padding: 3.125rem 0;
    height: 56.25rem;
    overflow: hidden;
  }

  .reviews .swiper-pagination-bullets {
    display: flex;
    right: 50%;
    top: 49.75%;
    transform: translateX(50%);
  }

  .reviews .swiper-pagination {
    text-align: center;
    padding-right: 0;
  }

  .reviews__block {
    padding: 0;
    margin-right: 0;
  }

  .reviews .swiper-slide-active {
    padding: 0 1.25rem;
  }

  .reviews .swiper-slide-next {
    padding: 0 1.25rem;
  }

  .reviews__item-wrapper {
    height: 15.625rem;
    width: 100%;
  }

  .reviews__item {
    padding: 2.5rem 1.5rem 2rem;
  }

  .reviews__item-quote {
    margin: 0 0 1rem;
    font-size: 0.875rem;
    line-height: 1.42;
  }

  .reviews__item-author {
    margin-bottom: 0;
    font-size: 0.875rem;
    line-height: 1.42;
  }

  .reviews__item-note {
    font-size: 0.625rem;
  }

  .reviews__widget {
    margin-top: 0;
    align-items: flex-start;
    padding-left: 1.25rem;
    flex-direction: column;
    padding-bottom: 2rem;
  }

  .reviews__widget-text {
    font-size: .75rem;
    margin-bottom: .75rem;
  }

  .testimonials {
    gap: 1.25rem;
  }

  .testimonials__quote {
    font-size: 1.375rem;
    line-height: 1.3;
  }

  .testimonials--case-studies {
    margin-bottom: 49px;
  }

  .testimonials--case-studies,
  .contact--case-studies {
    padding: 0 17px;
  }

  .testimonials__quotation {
    margin-top: 0;
  }

  .testimonials__quote {
    margin-bottom: 3rem;
  }

  .testimonials__image {
    width: 54px;
    height: 54px;
  }

  .author__name {
    font-size: 0.875rem;
  }

  .author__title {
    font-size: 12px;
  }

  /* CUSTOMERS */
  .customers {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 2rem);
    row-gap: 1rem;
  }

  .customers__item {
    max-width: 5rem;
  }

  /* WE MAKE IT BETTER */
  .it-better {
    padding: 1.5rem 1.5rem 0;
  }

  .it-better__container {
    position: relative;
  }

  .it-better__background {
    position: absolute;
    top: -1.5rem;
    bottom: 1.5rem;
    right: -1.5rem;
    left: 4.5rem;
  }

  .it-better__corner.rt {
    right: 0;
    top: 0;
  }

  .it-better__corner.lb {
    bottom: 0;
    left: 0;
  }

  .it-better__white-block {
    width: 6rem;
    height: 6rem;
    right: -1.5rem;
  }

  .it-better__white-block.top {
    top: -1.5rem;
  }

  .it-better__white-block.middle {
    display: none;
  }

  .it-better__content {
    position: static;
    padding: 1.5rem 0 0;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.42;
  }

  .it-better__content > .h1 {
    margin-bottom: 1.5rem;
  }

  .it-better__content p:last-child {
    margin-bottom: 0;
  }

  /* TECH STACK */
  #tech-stack .section-title--center {
    font-size: 1.5rem;
  }

  .tech-stack {
    row-gap: 1rem;
  }

  .tech-stack__item {
    max-width: 3.5rem;
  }

  /* BOXES */
  .boxes {
    max-width: 20.0625rem;
    grid-template-columns: repeat(2, 1fr);
  }

  /* EXPERTS */
  .experts {
    flex-direction: column-reverse;
    justify-content: flex-start;
    row-gap: 3.125rem;
    flex-wrap: unset;
    padding-bottom: 0;
  }

  .experts__container {
    position: relative;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .experts__content {
    padding: 1.5rem 0 0;
    max-width: 100%;
  }

  .experts__photo {
    width: 100%;
    height: auto;
  }

  .experts__background {
    top: 3rem;
    right: -1.5rem;
    bottom: -1.5rem;
    width: auto;
    left: 6rem;
  }

  .experts__corner.rb {
    right: -1.5rem;
    bottom: -1.5rem;
  }

  /* TECHNOLOGY */
  .technology {
    grid-template-rows: 3rem;
  }

  /* CONTACT */

  .contact {
    height: fit-content;
    flex-direction: column-reverse;
    background: linear-gradient(240.98deg, rgba(234, 240, 246, 0.109375) -6.98%, #EAF0F6 32.21%, rgba(234, 240, 246, 0) 81.54%);
  }

  .contact:before {
    display: none;
  }

  .contact__image {
    width: 100%;
  }

  .contact__content {
    padding: 3.4375rem 1.25rem;
    width: 100%;
  }

  /* ================================================ */
  /* FOOTER */
  footer {
    padding: 3rem 0;
  }

  .footer {
    flex-direction: column-reverse;
    justify-content: flex-start;
    flex-wrap: unset;
    font-size: 0.75rem;
  }

  .footer__details {
    padding-top: 3rem;
    border-top: 1px solid rgba(233, 243, 255, 10%);
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2.5rem;
    row-gap: 2rem;
  }

  .footer__details-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }

  .footer__details-logo {
    display: none;
  }

  .footer__socials {
    font-size: 1.125rem;
    margin-top: 0;
  }

  a.footer__socials-item,
  a.footer__socials-item:link,
  a.footer__socials-item:hover {
    width: 1.125rem;
  }

  .footer__sections {
    justify-content: flex-start;
    margin: 3rem 0;
  }

  .footer__section:last-child {
    text-align: left;
  }

  .footer__section-item {
    margin-bottom: 4rem;
  }

  .footer-menu__item {
    margin-bottom: 0.5rem;
  }

  .footer-menu__item:last-child {
    margin-bottom: 0;
  }

  .footer__section-title {
    font-size: 1.25rem;
    line-height: 1;
    margin-bottom: 1rem;
  }

  .footer__header-mobile {
    display: flex;
    padding-bottom: 3rem;
    border-bottom: 1px solid rgba(233, 243, 255, 10%);
  }
}
